import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faFileArrowUp, faHouse, faLaptop, faMicrophone, faSignOut, faSquarePhoneFlip, faTable, faUpload } from '@fortawesome/free-solid-svg-icons';
import { UploadLeadModal } from "../components/UploadLeadModal";
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { useContextStateManagement } from "../context/ContextApi";
import { Link } from "react-router-dom";

export const Sidebar = ({ setIsUploadLead, isTabClick }) => {
  const [showModal, setShowModal] = useState(false);
  const { sideBarLinkActive, activeTab, setPracticeId, setSideBarLinkActive, fetchApiHandle, setActiveTab } = useContextStateManagement();
  const dispatch = useDispatch();

  const handleModalOpen = () => {
    setIsUploadLead(false);
    if (activeTab == "careRTab" || activeTab == "visitRTab" || activeTab == "surveyTab") {
      setShowModal(false);
    }
    else {
      setShowModal(true);
    }
  };

  const handleModalClose = () => setShowModal(false);
  
  const bulkUploadLead = () => {
    if (activeTab == "careRTab" || activeTab == "visitRTab" || activeTab == "surveyTab"){
      setSideBarLinkActive("careConsole");
      document.getElementById("bulkUploadLead").setAttribute("disabled", "true");
    }
    else{
      sideBarLinkHandle("bulkUpload");
      document.getElementById("bulkUploadLead").removeAttribute("disabled");
    }
  }

  // logout handle
  const tokenExpiryLogoutHandle = () => {
    setActiveTab("pAuthQTab");
    setPracticeId("");
    dispatch(logout());
  }

  const sideBarLinkHandle = (sideBarLinkActive) => {
    if (sideBarLinkActive === "careConsole") {
      setSideBarLinkActive("careConsole");
    } else if (sideBarLinkActive === "bulkUpload") {
      setSideBarLinkActive("bulkUpload");
    } else if (sideBarLinkActive === "callSettings") {
      setSideBarLinkActive("callSettings")
    } else if (sideBarLinkActive === "onBoardingForm") {
      setSideBarLinkActive("onBoardingForm")
    } else if (sideBarLinkActive === "dashboard") {
      setSideBarLinkActive("dashboard")
    }
  }

  const callSettingsHandle = () => {
    sideBarLinkHandle("callSettings")
    fetchApiHandle()
  }

  return (
    <>
      <div className="sideBar fs-6 d-flex flex-column align-items-center align-items-sm-start ps-1 pt-5 text-white min-vh-100">
        <ul className="nav flex-column mb-0 align-items-center align-items-sm-start">
          <li className="nav-item">
            <a href="#" className="nav-link align-middle p-0">
              <FontAwesomeIcon icon={faHouse} />
              <span className="ms-1 d-none d-sm-inline">Home</span>
            </a>
          </li>
        </ul>
        <a
          href="#"
          className="d-flex align-items-center pb-2 pt-4 mb-md-0 me-md-auto text-secondary text-decoration-none"
        >
          <i className="fa fa-microphone "></i>
          <FontAwesomeIcon icon={faMicrophone} className="text-white btn bg-secondary" />
          <span className="mx-2 d-none d-sm-inline pl-5 emergencyHelp">
            Emergency
          </span>
        </a>
        <ul
          className="nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          <li className="nav-item">
            <a href="#" className={`care-console-link nav-link ${sideBarLinkActive === "careConsole" ? "active" : ""}  align-middle px-0`} onClick={() => sideBarLinkHandle("careConsole")}>
              <FontAwesomeIcon icon={faTable} />
              <span className="ms-1 d-none d-sm-inline">Care Console</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className={`nav-link align-middle px-0 ${isTabClick === true ? "nav-disabled" : ""}`} onClick={handleModalOpen} >
              <FontAwesomeIcon icon={faUpload} />
              <span className="ms-1 d-none d-sm-inline">Upload Lead</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className={`nav-link align-middle ${isTabClick === true ? "nav-disabled" : ""} ${sideBarLinkActive === "bulkUpload" ? "active" : ""} px-0`} onClick={bulkUploadLead}>
              <FontAwesomeIcon icon={faFileArrowUp} />
              <span className="ms-1 d-none d-sm-inline">Bulk Upload</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className={`nav-link align-middle ${sideBarLinkActive === "callSettings" ? "active" : ""}  px-0`}>
              <FontAwesomeIcon icon={faSquarePhoneFlip} />
              <span className="ms-1 d-none d-sm-inline" onClick={callSettingsHandle}>Call Settings</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className={`nav-link align-middle ${sideBarLinkActive === "onBoardingForm" ? "active" : ""}  px-0`}>
              <FontAwesomeIcon icon={faSquarePhoneFlip} />
              <span className="ms-1 d-none d-sm-inline" onClick={() => sideBarLinkHandle("onBoardingForm")}>Upload OnBoarding</span>
            </a>
          </li>
          <li className="nav-item">
            <Link to="/Dashboard" className={`nav-link ${sideBarLinkActive === "dashboard" ? "active" : ""} align-middle px-0`}>
              <FontAwesomeIcon icon={faLaptop} />
              <span className="ms-1 d-none d-sm-inline" onClick={() => setSideBarLinkActive("dashboard")}>Dashboards</span>
            </Link>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link align-middle px-0">
              <FontAwesomeIcon icon={faCircleCheck} />
              <span className="ms-1 d-none d-sm-inline">Subscription</span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a href="#" className="nav-link align-middle px-0" >
              <FontAwesomeIcon icon={faBell} />
              <span className="ms-1 d-none d-sm-inline"> Portal Notifications </span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link align-middle px-0">
              <FontAwesomeIcon icon={faPhone} />
              <span className="ms-1 d-none d-sm-inline">In Bound Calls</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link align-middle px-0">
            <FontAwesomeIcon icon={faWindowMaximize} />
              <span className="ms-1 d-none d-sm-inline">Website</span>
            </a>
          </li> */}
          <li className="nav-item">
            <a href="#" className="nav-link align-middle px-0">
              <FontAwesomeIcon icon={faSignOut} />
              <span onClick={tokenExpiryLogoutHandle} className="ms-1 d-none d-sm-inline">Sign out</span>
            </a>
          </li>
        </ul>
      </div>
      <UploadLeadModal setIsUploadLead={setIsUploadLead} showModal={showModal} closeModal={handleModalClose} />
    </>
  );
};
