import React, { useEffect, useState } from "react";
import { CircularProgress, Box } from '@mui/material';
// import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { useContextStateManagement } from "../context/ContextApi";
import PriorAuthQBulkUploadLead from "../assets/bulkUploadLeadFiles/PriorAuthQBulkUploadLead.csv";
import PAuthStatusBulkUploadLead from "../assets/bulkUploadLeadFiles/PAuthStatusBulkUploadLead.csv";
import BenefitsVerificationBulkUploadLead from "../assets/bulkUploadLeadFiles/BenefitsVerificationBulkUploadLead.csv";
import CareReminderBulkUploadLead from "../assets/bulkUploadLeadFiles/CareReminderBulkUploadLead.csv";
import VisitReminderBulkUploadLead from "../assets/bulkUploadLeadFiles/VisitReminderBulkUploadLead.csv";
import AwarenessCampaignBulkUploadLead from "../assets/bulkUploadLeadFiles/AwarenessCampaignBulkUploadLead.csv";
import LeadGenerationBulkUploadLead from "../assets/bulkUploadLeadFiles/LeadGenerationBulkUploadLead.csv";

export const BulkUploadLead = () => {
    const [file, setFile] = useState();
    const [providerData, setProviderData] = useState([]);
    const [loadingEnable, setLoadingEnable] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [providerId, setProviderId] = useState("");
    const dispatch = useDispatch();
    const { PracticeId, activeTab, setSideBarLinkActive, token, setToken, fetchUserRecord, activeOnBoardingTem, usersPostRequest } = useContextStateManagement();

    const fetchApiHandle = async () => {
        const userData = await fetchUserRecord(token, activeOnBoardingTem);
        if (userData == "Token is expired, login again") {
            setIsTokenExpired(true)
        }
        else {
            setProviderData(userData)
        }
    }

    useEffect(() => {
        fetchApiHandle();
    }, [activeOnBoardingTem, activeTab])

    // const fileReader = new FileReader();

    let handleOnChange = (e) => {
        setFile(e.target.files[0]);
        document.getElementById('submitBulkUpload').removeAttribute("disabled");
    }

    const csvFileToArray = async (file) => {

        let leadsArray;
        setLoadingEnable(true)
        Papa.parse(file, {
            complete: async (result) => {

                // set data after reading csv file
                leadsArray = result.data;

                // get selected provider data
                let selectedProviderData = providerData.filter((getProvider) => {
                    setProviderId("");
                    return getProvider.Id == providerId;
                })

                // set selectedProvider details in leadsArray
                if (activeTab == "pAuthQTab") {
                    for (const obj of leadsArray) {
                        obj.PracticeId = PracticeId;
                        obj.ProviderId = selectedProviderData[0].Id;
                        obj.ProviderName = selectedProviderData[0].ProviderName;
                        obj.ProviderFacilityName = selectedProviderData[0].ProviderFacilityName;
                        obj.NationalProviderIdentifier = selectedProviderData[0].NationalProviderIdentifier;
                        obj.ProviderContactNumber = selectedProviderData[0].ProviderContactNumber;
                        obj.ProviderFaxNumber = selectedProviderData[0].ProviderFaxNumber;
                        obj.ProviderEmailAddress = selectedProviderData[0].ProviderEmailAddress;
                    }

                    if (Object.keys(leadsArray[0]).length === 19) {
                        let url = "/api/PriorAuthQLead";
                        const userData = await usersPostRequest(token, url, leadsArray);
                        if (userData == "Token is expired, login again") {
                            setIsTokenExpired(true);
                            return
                        }
                        else if (userData && userData.message) {
                            setDataSubmitted(true);
                        } else {
                            setDataSubmitted(false);
                            return
                        }

                    } else {
                        alert("Invalid file data")
                        setLoadingEnable(false)
                        document.getElementById("selectedProviderId").value = "";
                        return
                    }

                } else if (activeTab == "pAuthSTab") {
                    for (const obj of leadsArray) {
                        obj.PracticeId = PracticeId;
                        obj.ProviderId = selectedProviderData[0].Id;
                        obj.ProviderName = selectedProviderData[0].ProviderName;
                        obj.ProviderFacilityName = selectedProviderData[0].ProviderFacilityName;
                        obj.NationalProviderIdentifier = selectedProviderData[0].NationalProviderIdentifier;
                        obj.ProviderContactNumber = selectedProviderData[0].ProviderContactNumber;
                        obj.ProviderFaxNumber = selectedProviderData[0].ProviderFaxNumber;
                        obj.ProviderEmailAddress = selectedProviderData[0].ProviderEmailAddress;
                    }

                    if (Object.keys(leadsArray[0]).length === 18) {
                        let url = "/api/PAuthStatusUploadLead";
                        const userData = await usersPostRequest(token, url, leadsArray);
                        if (userData == "Token is expired, login again") {
                            setIsTokenExpired(true);
                            return
                        }
                        else if (userData && userData.message) {
                            setDataSubmitted(true);
                        } else {
                            setDataSubmitted(false);
                            return
                        }

                    } else {
                        alert("Invalid file data")
                        setLoadingEnable(false)
                        document.getElementById("selectedProviderId").value = "";
                        return
                    }
                } else if (activeTab == "benefitVTab") {
                    for (const obj of leadsArray) {
                        obj.PracticeId = PracticeId;
                        obj.ProviderId = selectedProviderData[0].Id;
                        obj.ProviderName = selectedProviderData[0].ProviderName;
                        obj.ProviderFacilityName = selectedProviderData[0].ProviderFacilityName;
                        obj.NationalProviderIdentifier = selectedProviderData[0].NationalProviderIdentifier;
                        obj.ProviderContactNumber = selectedProviderData[0].ProviderContactNumber;
                        obj.ProviderFaxNumber = selectedProviderData[0].ProviderFaxNumber;
                        obj.ProviderEmailAddress = selectedProviderData[0].ProviderEmailAddress;
                    }

                    if (Object.keys(leadsArray[0]).length === 17 && leadsArray[0].hasOwnProperty("PatientInsuranceContactNumber")) {
                        let url = "/api/BenefitsVerificationUploadLead";
                        const userData = await usersPostRequest(token, url, leadsArray);
                        if (userData == "Token is expired, login again") {
                            setIsTokenExpired(true);
                            return
                        }
                        else if (userData && userData.message) {
                            setDataSubmitted(true);
                        } else {
                            setDataSubmitted(false);
                            return
                        }

                    } else {
                        alert("Invalid file data")
                        setLoadingEnable(false)
                        document.getElementById("selectedProviderId").value = "";
                        return
                    }
                } 
		 // else if (activeTab == "careRTab") {
                //     sideBarLinkHandle("")
                //     for (const obj of leadsArray) {
                //         obj.PracticeId = PracticeId;
                //         obj.ProviderId = selectedProviderData[0].Id;
                //         obj.ProviderName = selectedProviderData[0].ProviderName;
                //         obj.ProviderFacilityName = selectedProviderData[0].ProviderFacilityName;
                //         obj.ProviderContactNumber = selectedProviderData[0].ProviderContactNumber;
                //     }

                //     if (Object.keys(leadsArray[0]).length === 13) {
                //         let url = "/api/CareRemindersUploadLead";
                //         const userData = await usersPostRequest(token, url, leadsArray);
                //         if (userData == "Token is expired, login again") {
                //             setIsTokenExpired(true);
                //             return
                //         }
                //         else if (userData && userData.message) {
                //             setDataSubmitted(true);
                //         } else {
                //             setDataSubmitted(false);
                //             return
                //         }

                //     } else {
                //         alert("Invalid file data")
                //         setLoadingEnable(false)
                //         document.getElementById("selectedProviderId").value = "";
                //         return
                //     }
                // } 
                // else if (activeTab == "visitRTab") {
                //     sideBarLinkHandle("");
                // //     for (const obj of leadsArray) {
                // //         obj.PracticeId = PracticeId;
                // //         obj.ProviderId = selectedProviderData[0].Id;
                // //         obj.ProviderName = selectedProviderData[0].ProviderName;
                // //         obj.ProviderFacilityName = selectedProviderData[0].ProviderFacilityName;
                // //         obj.ProviderSpecialtyType = selectedProviderData[0].ProviderSpecialtyType;
                // //         obj.ProviderContactNumber = selectedProviderData[0].ProviderContactNumber;
                // //     }
                    
                // //     if (Object.keys(leadsArray[0]).length === 15 && leadsArray[0].hasOwnProperty("AppointmentType")) {
                // //         let url = "/api/VisitRemindersUploadLead";
                // //         const userData = await usersPostRequest(token, url, leadsArray);
                // //         if (userData == "Token is expired, login again") {
                // //             setIsTokenExpired(true);
                // //             return
                // //         }
                // //         else if (userData && userData.message) {
                // //             setDataSubmitted(true);
                // //         } else {
                // //             setDataSubmitted(false);
                // //             return
                // //         }

                // //     } else {
                // //         alert("Invalid file data")
                // //         setLoadingEnable(false)
                // //         document.getElementById("selectedProviderId").value = "";
                // //         return
                // //     }
                // } 
		 else if (activeTab == "awareNessCTab") {
                    for (const obj of leadsArray) {
                        obj.PracticeId = PracticeId;
                        obj.ProviderId = selectedProviderData[0].Id;
                        obj.ProviderName = selectedProviderData[0].ProviderName;
                        obj.ProviderFacilityName = selectedProviderData[0].ProviderFacilityName;
                        obj.ProviderSpecialtyType = selectedProviderData[0].ProviderSpecialtyType;
                        obj.AdditionalOfferedService = selectedProviderData[0].AdditionalOfferedService;
                        obj.ProviderContactNumber = selectedProviderData[0].ProviderContactNumber;
                        obj.AdditionalOfferedServiceDetail = selectedProviderData[0].AdditionalOfferedServiceDetail;
                        obj.BetterServicesReason = selectedProviderData[0].BetterServicesReason;
                    }

                    if (Object.keys(leadsArray[0]).length === 14) {
                        let url = "/api/AwarenessCampaignUploadLead";
                        const userData = await usersPostRequest(token, url, leadsArray);
                        if (userData == "Token is expired, login again") {
                            setIsTokenExpired(true);
                            return
                        }
                        else if (userData && userData.message) {
                            setDataSubmitted(true);
                        } else {
                            setDataSubmitted(false);
                            return
                        }

                    } else {
                        alert("Invalid file data")
                        setLoadingEnable(false)
                        document.getElementById("selectedProviderId").value = "";
                        return
                    }
                } else if (activeTab == "leadGTab") {
                    for (const obj of leadsArray) {
                        obj.PracticeId = PracticeId;
                        obj.ProviderId = selectedProviderData[0].Id;
                        obj.ProviderName = selectedProviderData[0].ProviderName;
                        obj.ProviderFacilityName = selectedProviderData[0].ProviderFacilityName;
                        obj.ProviderSpecialtyType = selectedProviderData[0].ProviderSpecialtyType;
                        obj.AdditionalOfferedService = selectedProviderData[0].AdditionalOfferedService;
                        obj.AdditionalOfferedServiceDetail = selectedProviderData[0].AdditionalOfferedServiceDetail;
                        obj.BetterServicesReason = selectedProviderData[0].BetterServicesReason;
                        obj.PatientIssues = selectedProviderData[0].PatientIssues;
                        obj.CustomerWebsite = selectedProviderData[0].CustomerWebsite;
                        obj.OperationalHours = selectedProviderData[0].OperationalHours;
                        obj.InsurancesAccepted = selectedProviderData[0].InsurancesAccepted;
                    }

                    if (Object.keys(leadsArray[0]).length + 5 === 20) {
                        let url = "/api/LeadGenerationUploadLead";
                        const userData = await usersPostRequest(token, url, leadsArray);
                        if (userData == "Token is expired, login again") {
                            setIsTokenExpired(true);
                            return
                        }
                        else if (userData && userData.message) {
                            setDataSubmitted(true);
                        } else {
                            setDataSubmitted(false);
                            return
                        }

                    } else {
                        alert("Invalid file data")
                        setLoadingEnable(false)
                        document.getElementById("selectedProviderId").value = "";
                        return
                    }
                }

            },
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
        });

    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (file && providerId) {
            document.getElementById("submitBulkUpload").classList.add("toggleColor");
            e.target.disabled = true;
            // setProviderId("")
            // setFile("")
            const fileType = file.type;
            if (fileType == 'text/csv') {
                await csvFileToArray(file);
            }
            // else if (fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            //     fileReader.onload = async (event) => {
            //         const workbook = XLSX.read(event.target.result, { type: 'binary' });
            //         console.log(workbook)
            //         const sheetName = workbook.SheetNames[0];
            //         console.log(sheetName)
            //     }

            //     fileReader.readAsBinaryString(file);
            // }
        }
    }

    useEffect(() => {
        if (file && providerId) {
            document.getElementById("submitBulkUpload").classList.remove("toggleColor");
            document.getElementById("submitBulkUpload").removeAttribute("disabled");
        } else {
            document.getElementById("submitBulkUpload").classList.add("toggleColor");
            document.getElementById("submitBulkUpload").setAttribute("disabled", "true");
        }
    }, [file, providerId]);

    useEffect(() => {
        if (dataSubmitted === true) {
            setLoadingEnable(false)
            alert('Bulk upload process has been completed successfully!')
            setSideBarLinkActive("careConsole")
            setDataSubmitted(false);
        } else {
            setLoadingEnable(false)
            // alert('Bulk upload process not completed!')
        }
    }, [dataSubmitted]);


    useEffect(() => {
        if (isTokenExpired === true) {
            alert("Token is expired, login again");
            setToken("")
            setIsTokenExpired(false)
            dispatch(logout());
        }
    }, [isTokenExpired]);

    return (
        <>
            <div className="position-relative">
                {loadingEnable === true ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'absolute', top: '37%', left: '43%' }}>
                            <CircularProgress />
                        </Box>
                    </> : ""
                }
                <h5 className="my-2 ps-3">Bulk Upload your lead</h5>
                <div className="fileUploader-container p-3 mb-2 rounded-3">
                    <h6 className="p-0 mb-3">File Uploader </h6>
                    <div className={`fileDrop-container ${loadingEnable === true ? "opacity-75" : ""} text-center rounded-3`}>
                        <div id="dropText" className="my-5">
                            <p className="mb-0">Drop file here or click to select file</p>
                            <p className="mb-0">(allowed: *.CSV, Max size: 200 MB)</p>
                            <input type={"file"} id="csvFileInput" accept={".csv"} onChange={handleOnChange} />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-start align-item-center position-relative dropdownProvider ps-3 mb-2">
                    <select className="form-select text-white me-2" id="selectedProviderId" onChange={(e) => setProviderId(e.target.value)}>
                        <option className="selectedProviderOption" value="">Select Provider</option>
                        {
                            providerData.length > 0 ?
                                providerData.map((provider) => {
                                    return (
                                        <option className="selectedProviderOption" value={provider.Id}>{provider.ProviderName}</option>
                                    );
                                })
                                : ""
                        }
                    </select>
                    <span>
                        {/* <FontAwesomeIcon className="position-absolute caretDown" id="caretDown" icon={faCaretDown}/> */}
                    </span>
                    <button className="submitButton simple-btn" id="submitBulkUpload" onClick={handleOnSubmit}>Process Bulk Upload Lead</button>
                </div>
                <div className="ps-3">
                    <h6 className="mb-0">Don't have a CSV?</h6>
                    <p className="mb-2">Use our template to create one.
                        {
                            activeTab == "pAuthQTab" ?
                                <a href={PriorAuthQBulkUploadLead} download="PriorAuthQBulkUploadLead.csv">Click here</a>
                                : activeTab == "pAuthSTab" ?
                                    <a href={PAuthStatusBulkUploadLead} download="PAuthStatusBulkUploadLead.csv">Click here</a>
                                    : activeTab == "benefitVTab" ?
                                        <a href={BenefitsVerificationBulkUploadLead} download="BenefitsVerificationBulkUploadLead.csv">Click here</a>
                                        : activeTab == "careRTab" ?
                                            <a href={CareReminderBulkUploadLead} download="CareReminderBulkUploadLead.csv">Click here</a>
                                            : activeTab == "visitRTab" ?
                                                <a href={VisitReminderBulkUploadLead} download="VisitReminderBulkUploadLead.csv">Click here</a>
                                                : activeTab == "awareNessCTab" ?
                                                    <a href={AwarenessCampaignBulkUploadLead} download="AwarenessCampaignBulkUploadLead.csv">Click here</a>
                                                    : <a href={LeadGenerationBulkUploadLead} download="LeadGenerationBulkUploadLead.csv">Click here</a>
                        }
                        to download it.
                    </p>
                </div>
            </div>
        </>
    )
}
